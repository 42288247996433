import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TOC_ITEMS } from "pages/toc-detail/constants/index.constant";
import { TOCDetailType } from "pages/toc-detail/toc-detail-types";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "shared/constant/constants";
import { convertMetersToMiles } from "shared/methods/utilityFunctions";
import {
  fetchTransitionOfCareDetailsAsync,
  fetchTransitionOfCareItemsAsync,
  getAllTocFacilitiesAsync,
  approveTocRevisionAsync,
  getPacTypesAsync,
  getAchFacilities,
  getEpisodePatientDetailsAsync,
} from "state/features/toc/toc.action";
import {
  CarePlanData,
  IEpisodePatientDetails,
  IFacility,
  Item,
  ITocState,
} from "state/types/toc-slice.type";

const initialState: ITocState = {
  isTocDetailsLoading: true,
  isEpisodePatientDetailsLoading: true,
  isLoadingApproveButton: false,
  isLoading: false,
  isSilentLoading: false,
  isSearching: false,
  isError: false,
  isValid: false,
  expandedTocIndex: 0,
  suggestions: "",
  tocEditFormDirty: false,
  counts: {
    pending: 0,
    approved: 0,
  },
  tocs: {
    pending: [],
    approved: [],
  },
  tocDetails: [],
  patientDetails: null,
  facilities: [],
  pacTypes: [],
  carePlanData: [],
  allFacilities: [],
  tocEditForm: {
    isTocValueChanged: false,
    acuteLos: {
      value: "",
      error: "",
    },
    navigatorNotes: null,
    physicianNotes: null,
    isHomeWithNoServicesEnabled: false,
    tocItemsForm: TOC_ITEMS,
    isTocItemsFormDirty: false,
    editTocDetails: null,
  },
  originalCarePlanData: [],
};

const tocSlice = createSlice({
  name: "toc",
  initialState: initialState,
  reducers: {
    setIsLoading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setIsSearching: (state: any, action) => {
      state.isSearching = action.payload;
    },
    setError: (state: any, action) => {
      state.isError = action.payload;
    },
    setExpandedTocIndex: (state: any, action) => {
      state.expandedTocIndex = action.payload;
    },
    setTocSuggestions: (state: any, action) => {
      state.suggestions = action.payload;
    },
    resetTocs: () => initialState,
    setHomeWithoutNoService(state, action) {
      state.tocEditForm.isHomeWithNoServicesEnabled = action.payload;
    },
    setIsTocValueChanged(state, action) {
      state.tocEditForm.isTocValueChanged = action.payload;
    },
    updateTocDetailsList(state, action) {
      state.tocDetails = action.payload;
    },
    setEditTocDetails(state, action: PayloadAction<Item>) {
      state.tocEditForm.editTocDetails = action.payload;
    },
    resetTocItemsForm(state) {
      state.tocEditForm.tocItemsForm = structuredClone(TOC_ITEMS);
    },
    resetTocEditForm(state) {
      state.tocEditForm = structuredClone(initialState.tocEditForm);
    },
    setCarePlanData(state, action) {
      state.carePlanData = action.payload.map(
        (item: CarePlanData, index: number) => {
          return {
            admission: item.admission,
            daysType: item.daysType,
            longName: item.longName,
            pacTypeId: item.pacTypeId,
            quantity: item.quantity,
            sequence: index,
            shortName: item?.shortName ?? "",
            standardCarePlanId: -1,
            id: item.id,
            providerId: item.providerId,
            providerName: item.providerName,
          };
        }
      );
    },
    setNavigatorAndAdditionalNotes: (state, action) => {
      state.tocEditForm.navigatorNotes = action.payload.navigatorNotes;
      state.tocEditForm.physicianNotes = action.payload.additionalNotes;
    },
    updateAchFacilites(state, action) {
      state.facilities = action.payload;
    },

    setAchDistance(state, action) {
      const facilities: IFacility[] = [];
      const selectedPlaces = action.payload.selectedPlaces;
      const response = action.payload.data;
      const selectedFacilityIds = selectedPlaces.map((el: any) => el.id);

      state.facilities.forEach((fac: IFacility) => {
        if (selectedFacilityIds.includes(fac.id)) {
          const index = selectedFacilityIds.findIndex(
            (el: any) => el === fac.id
          );
          const entry = response.rows[0].elements[index];
          if (entry.distance) {
            const distance = convertMetersToMiles(entry.distance.value);
            facilities.push({ ...fac, distance } as IFacility);
          } else {
            facilities.push({ ...fac } as IFacility);
          }
        } else {
          facilities.push({ ...fac } as IFacility);
        }
      });

      facilities.sort((a, b) => {
        const distA = a.distance.length
          ? parseFloat(a.distance.split(" ")[0])
          : Number.MAX_SAFE_INTEGER;
        const distB = b.distance.length
          ? parseFloat(b.distance.split(" ")[0])
          : Number.MAX_SAFE_INTEGER;

        return distA - distB;
      });
      state.facilities = facilities;
    },
    setAllFacilitiesDistance(state, action) {
      const facilities: IFacility[] = [];
      const selectedPlaces = action.payload.selectedPlaces;
      const response = action.payload.data;
      const selectedFacilityIds = selectedPlaces.map((el: any) => el.id);

      state.allFacilities.forEach((fac: IFacility) => {
        if (selectedFacilityIds.includes(fac.id)) {
          const index = selectedFacilityIds.findIndex(
            (el: any) => el === fac.id
          );
          const entry = response.rows[0].elements[index];
          if (entry.distance) {
            const distance = convertMetersToMiles(entry.distance.value);
            facilities.push({ ...fac, distance } as IFacility);
          } else {
            facilities.push({ ...fac } as IFacility);
          }
        } else {
          facilities.push({ ...fac } as IFacility);
        }
      });

      facilities.sort((a, b) => {
        const distA = a.distance.length
          ? parseFloat(a.distance.split(" ")[0])
          : Number.MAX_SAFE_INTEGER;
        const distB = b.distance.length
          ? parseFloat(b.distance.split(" ")[0])
          : Number.MAX_SAFE_INTEGER;

        return distA - distB;
      });
      state.allFacilities = facilities;
    },
    setOriginalCarePlanData(state, action) {
      state.originalCarePlanData = action.payload.map(
        (item: CarePlanData, index: number) => {
          return {
            admission: item.admission,
            daysType: item.daysType,
            longName: item.longName,
            pacTypeId: item.pacTypeId,
            quantity: item.quantity,
            sequence: index,
            shortName: "",
            standardCarePlanId: -1,
            id: item.id,
            providerId: item.providerId,
          };
        }
      );
    },
    setTocEditForm(state, action) {
      state.tocEditForm = action.payload;
    },
    addNewTocService(state, action) {
      state.carePlanData.push(action.payload);
      state.tocEditFormDirty = false;
    },
    editTocService(state, action) {
      const index = state.carePlanData.findIndex(
        (carePlan) => carePlan.longName === action.payload.longName
      );
      state.carePlanData[index] = {
        ...state.carePlanData[index],
        longName: action.payload.longName,
        providerId: action.payload.providerId,
        providerName: action.payload.providerName,
        quantity: action.payload.quantity,
        isPrefferedProvider: action.payload.prefferedProvider,
      };
      state.tocEditFormDirty = false;
    },
    deletePacTypeItem(state, action) {
      const index = state.carePlanData.findIndex(
        (carePlan) => carePlan.longName === action.payload
      );
      const copyOfCarePlanData = [...state.carePlanData];
      if (index !== -1) {
        copyOfCarePlanData.splice(index, 1);
        state.carePlanData = copyOfCarePlanData;
      }
      state.tocEditFormDirty = false;
    },
    setIsTocDirtyForm(state, action) {
      state.tocEditFormDirty = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(
        fetchTransitionOfCareItemsAsync.pending,
        (state: any, action) => {
          if (action.meta.arg && action.meta.arg.offset > 0) {
            state.isLoading = false;
            state.isSilentLoading = true;
          } else {
            state.isLoading = true;
            state.isSilentLoading = false;
            state.tocs = {
              pending: [],
              approved: [],
            };
            state.counts = {
              pending: 0,
              approved: 0,
            };
          }
          state.isError = false;
        }
      ),
      builder.addCase(
        fetchTransitionOfCareItemsAsync.fulfilled,
        (state: any, action: any) => {
          const counts = action.payload.Count;
          const tocs = action.payload.ToCList;
          state.counts = {
            pending: counts.Pending,
            approved: counts.Approved,
          };
          if (state.isSilentLoading) {
            state.tocs = {
              pending: [...state.tocs.pending, ...tocs.Pending],
              approved: [...state.tocs.approved, ...tocs.Approved],
            };
          } else {
            state.tocs = {
              pending: tocs.Pending,
              approved: tocs.Approved,
            };
          }
          state.isLoading = false;
          state.isSilentLoading = false;
          state.isError = false;
        }
      ),
      builder.addCase(
        fetchTransitionOfCareItemsAsync.rejected,
        (state: any, action) => {
          if (!action.meta.aborted) {
            state.isLoading = false;
            state.isSilentLoading = false;
            toast.error(TOAST_MESSAGES.ERROR, {
              containerId: "main",
              toastId: "toc-error",
            });
          }
        }
      ),
      builder.addCase(
        fetchTransitionOfCareDetailsAsync.pending,
        (state: any, action) => {
          state.isTocDetailsLoading = true;
        }
      ),
      builder.addCase(
        fetchTransitionOfCareDetailsAsync.fulfilled,
        (state: any, action) => {
          state.tocDetails = action.payload.map(
            (item: TOCDetailType, index: number) => {
              if (index === 0) {
                item.isExpanded = true;
              }
              return item;
            }
          );
          state.isTocDetailsLoading = false;
        }
      ),
      builder.addCase(
        fetchTransitionOfCareDetailsAsync.rejected,
        (state: any, action) => {
          state.isTocDetailsLoading = false;
          toast.error(TOAST_MESSAGES.ERROR, {
            containerId: "main",
            toastId: "toc-error",
          });
        }
      ),
      builder.addCase(
        getAllTocFacilitiesAsync.fulfilled,
        (state: any, action) => {
          state.allFacilities = action.payload;
        }
      ),
      builder.addCase(approveTocRevisionAsync.rejected, (state: any) => {
        state.isLoadingApproveButton = false;
        state.isError = true;
      }),
      builder.addCase(approveTocRevisionAsync.pending, (state: any) => {
        state.isLoadingApproveButton = true;
        state.isError = false;
      }),
      builder.addCase(
        approveTocRevisionAsync.fulfilled,
        (state: any, action: any) => {
          state.isLoadingApproveButton = false;
          state.isError = !action.payload;
          state.isValid = action.payload;
        }
      ),
      builder.addCase(getPacTypesAsync.fulfilled, (state: any, action: any) => {
        state.pacTypes = action.payload;
      }),
      builder.addCase(getAchFacilities.fulfilled, (state, action) => {
        state.facilities = action.payload;
      }),
      builder.addCase(getEpisodePatientDetailsAsync.pending, (state) => {
        state.isEpisodePatientDetailsLoading = true;
      }),
      builder.addCase(
        getEpisodePatientDetailsAsync.fulfilled,
        (state, action: PayloadAction<IEpisodePatientDetails | null>) => {
          state.isEpisodePatientDetailsLoading = false;
          state.patientDetails = action.payload;
        }
      ),
      builder.addCase(
        getEpisodePatientDetailsAsync.rejected,
        (state, action) => {
          state.isEpisodePatientDetailsLoading = false;
          toast.error(TOAST_MESSAGES.ERROR, {
            containerId: "main",
            toastId: "toc-episode-patient-details-error",
          });
        }
      )
    );
  },
});

export default tocSlice.reducer;
export const {
  setIsTocValueChanged,
  setIsLoading,
  setIsSearching,
  setError,
  resetTocs,
  setExpandedTocIndex,
  setTocSuggestions,
  updateTocDetailsList,
  setCarePlanData,
  setEditTocDetails,
  resetTocEditForm,
  resetTocItemsForm,
  setAchDistance,
  setAllFacilitiesDistance,
  setHomeWithoutNoService,
  setNavigatorAndAdditionalNotes,
  updateAchFacilites,
  setOriginalCarePlanData,
  setTocEditForm,
  addNewTocService,
  editTocService,
  deletePacTypeItem,
  setIsTocDirtyForm,
} = tocSlice.actions;
export const getTocs = (state: any): ITocState => state.toc;
